<template>
  <div class="tc-evaluate-editUser">
    <!-- 页面功能 -->
    <div class="tool">
      <ul>
        <li>
          <img
            src="../../../../assets/images/refreshBtn.png"
            @click="refresh()"
            alt
          />
        </li>
        <li>
          <img
            src="../../../../assets/images/retreatBtn.png"
            @click="back()"
            alt
          />
        </li>
      </ul>
    </div>
    <el-form
      ref="form"
      :model="form"
      label-width="100px"
      size="mini"
      v-loading="loading"
      :element-loading-text="loadingText"
    >
      <!-- 用户必填信息 -->
      <el-row class="userinfo">
        <el-col :span="2">
          <el-col :span="10" class="avatarTitle">头像</el-col>
          <el-col :span="14">
            <el-upload
              class="avatar-uploader"
              action="/file_upload"
              :show-file-list="false"
              :on-success="fileUploadApi"
              :before-upload="beforeAvatarUpload"
              :data="fileData"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-col>
        </el-col>
        <!-- 第一列 -->
        <el-col :span="4">
          <el-form-item label="用户编号">
            <el-input v-model="form.account" disabled></el-input>
          </el-form-item>
          <el-form-item label="用户姓名">
            <el-input v-model="form.real_name"></el-input>
          </el-form-item>
        </el-col>
        <!-- 3 -->
        <el-col :span="4">
          <!-- <el-form-item label="出生日期">
            <el-date-picker
              :picker-options="pickerOptions0"
              value-format="yyyy-MM-dd"
              v-model="form.age"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item> -->
          <el-form-item label="年龄" prop="ages">
            <el-input
              type="number"
              v-model="form.ages"
              maxlength="16"
              :min="0"
            ></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <el-select v-model="form.sex">
              <el-option label="男" :value="1"></el-option>
              <el-option label="女" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- 4 -->
        <el-col :span="5">
          <el-form-item label="部门名称">
            <el-select v-model="form.department_id">
              <el-option
                v-for="item in departmentList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                >{{ item.name }}</el-option
              >
            </el-select>
          </el-form-item>
          <el-form-item label="文化程度">
            <el-select v-model="form.culture">
              <el-option label="文盲" value="文盲"></el-option>
              <el-option label="小学" value="小学"></el-option>
              <el-option label="初中" value="初中"></el-option>
              <el-option
                label="高中/中专/技校"
                value="高中/中专/技校"
              ></el-option>
              <el-option label="大学专科" value="大学专科"></el-option>
              <el-option label="大学本科" value="大学本科"></el-option>
              <el-option label="硕士" value="硕士"></el-option>
              <el-option label="博士及以上" value="博士及以上"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="用户名密码">
            <el-input
              v-model="form.password"
              placeholder="为空则不修改"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="身份证号码">
            <el-input
              maxlength="18"
              v-model="form.idcard"
              placeholder="为空则不修改"
            ></el-input>
          </el-form-item> -->
        </el-col>
      </el-row>
      <!-- 量表 -->
      <el-row class="scale">
        <el-tabs v-model="scale">
          <el-tab-pane label="所有可用量表" name="first">
            <div class="query">
              <p>量表权限设置</p>
              <el-form :inline="true" :model="form" size="mini">
                <el-form-item>
                  <el-input
                    v-model="form.scaleName"
                    placeholder="量表名称"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="queryScale()"
                    >量表查询</el-button
                  >
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="checkAll(1)"
                    >全选或取消所有量表</el-button
                  >
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="checkAll(2)"
                    >全选或取消测评人查看结果</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
            <!-- 所有量表 -->
            <div class="table" @change="handleCheckAllChange">
              <table>
                <tr>
                  <th>量表名称</th>
                  <th>使用该量表</th>
                  <th>允许测评人看测评结果</th>
                  <th>测评次数</th>
                </tr>
                <!-- ----------------------- -->
                <template v-for="item in scaleData">
                  <tr
                    class="title"
                    :key="item.id"
                    v-if="item.status_measure.length > 0"
                  >
                    <td colspan="4">{{ item.classify_name }}</td>
                  </tr>
                  <tr
                    v-for="item2 in item.status_measure"
                    :key="item2.classify_name"
                  >
                    <td>{{ item2.measure_title }}</td>
                    <td>
                      <el-checkbox-group v-model="checkList">
                        <el-checkbox :label="item2.id" name="a"></el-checkbox>
                      </el-checkbox-group>
                    </td>
                    <td>
                      <el-checkbox-group v-model="checkList2">
                        <el-checkbox :label="item2.id" name="b"></el-checkbox>
                      </el-checkbox-group>
                    </td>
                    <td>
                      <el-input
                        v-model="item2.max_num"
                        placeholder=""
                        size="mini"
                        :name="item2.id"
                        maxlength="2"
                        onkeyup="value=value.replace(/[^\d]/g,'')"
                      ></el-input>
                    </td>
                  </tr>
                </template>
              </table>
            </div>
            <el-col :span="5">
              <!-- <el-form-item
                label="每份量表测评次数"
                label-width="150px"
                v-if="false"
              >
                <el-input
                  v-model="form.measurement_num"
                  onkeyup="value=value.replace(/[^\d]/g,'')"
                  maxlength="3"
                ></el-input>
              </el-form-item> -->
              <el-form-item label="已测量表是否显示" label-width="150px">
                <el-radio-group v-model="form.show_finish_measure">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-tab-pane>
          <el-tab-pane label="量表组合包" name="second">
            <div class="query">
              <p>量表分组设置</p>
            </div>
            <div class="table">
              <table @change="handleCheckAllChange2">
                <tr>
                  <th>分组量表名称</th>
                  <th>量表数量</th>
                  <!-- <th>次数限制</th> -->
                  <th>应用该分组</th>
                </tr>
                <!-- ----------------------- -->
                <tr v-for="item in scaleData2.data" :key="item.id">
                  <td>{{ item.group_name }}</td>
                  <td>{{ item.measure_count }}</td>
                  <!-- <td>{{ item.test_num }}</td> -->
                  <td>
                    <el-checkbox-group v-model="checkList3">
                      <el-checkbox :label="item.id"></el-checkbox>
                    </el-checkbox-group>
                  </td>
                </tr>
              </table>
            </div>
          </el-tab-pane>
        </el-tabs>
        <el-col :span="5">
          <el-form-item label="测评时间范围" label-width="150px">
            <el-date-picker
              v-model="form.date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 问卷权限 -->
      <el-row class="msqtable">
        <p class="extend">问卷权限设置</p>
        <el-collapse-transition>
          <div class="table" @change="handleCheckAllChange3">
            <table>
              <tr>
                <th>问卷(试卷)名称</th>
                <th>使用该问卷</th>
                <!-- <th>允许测评人看到得分</th> -->
              </tr>
              <!-- ----------------------- -->
              <tr class="title">
                <td colspan="3">选择要授权的问卷</td>
              </tr>
              <!--  v-if="item[0].status_questionnaires !=0" -->
              <template v-for="item in questionnaireData">
                <tr
                  class="title"
                  :key="item.id"
                  v-if="item[0].status_questionnaires.length != 0"
                >
                  <td colspan="3">{{ item[0].type_name }}</td>
                </tr>
                <tr
                  v-for="item2 in item[0].status_questionnaires"
                  :key="item2.id"
                >
                  <td>{{ item2.title }}</td>
                  <td>
                    <el-checkbox-group v-model="checkList11">
                      <el-checkbox :label="item2.id" name="a"></el-checkbox>
                    </el-checkbox-group>
                  </td>
                  <!-- <td>
                    <el-checkbox-group v-model="checkList22">
                      <el-checkbox :label="item2.id" name="b"></el-checkbox>
                    </el-checkbox-group>
                  </td> -->
                </tr>
              </template>
            </table>
          </div>
        </el-collapse-transition>
      </el-row>
      <!-- 自填信息 -->
      <el-col :span="5">
        <template v-for="item in archivesData">
          <!-- 单行文本 -->
          <el-col :key="item.id" v-if="item.type === 1">
            <el-form-item :label="item.name">
              <el-input v-model="form.archives[item.key]"></el-input>
            </el-form-item>
          </el-col>
          <!-- 下拉框 -->
          <el-col :key="item.id" v-if="item.type === 3">
            <el-form-item :label="item.name">
              <el-select v-model="form.archives[item.key]">
                <el-option
                  :label="item2"
                  :value="item2"
                  v-for="(item2, key) in item.content"
                  :key="key"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 单选 -->
          <el-col :key="item.id" v-if="item.type === 4">
            <el-form-item :label="item.name">
              <el-radio-group v-model="form.archives[item.key]">
                <el-radio
                  :label="item2"
                  v-for="(item2, key) in item.content"
                  :key="key"
                ></el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <!-- 复选 -->
          <el-col :key="item.id" v-if="item.type === 5">
            <el-form-item :label="item.name">
              <el-checkbox-group v-model="form.archives[item.key]">
                <el-checkbox
                  :label="item2"
                  v-for="(item2, key) in item.content"
                  :key="key"
                ></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <!-- 文本域 -->
          <el-col :key="item.id" v-if="item.type === 2">
            <el-form-item :label="item.name">
              <el-input
                type="textarea"
                v-model="form.archives[item.key]"
                :rows="5"
              ></el-input>
            </el-form-item>
          </el-col>
        </template>
      </el-col>
      <el-col>
        <div class="btn">
          <el-button type="primary" @click="editBtn">确认修改</el-button>
        </div>
      </el-col>
    </el-form>
  </div>
</template>

<script>
import axios from "axios";
import { userInfo, loadArchivesInfo, adminUpdateUser } from "@/api/evaluate.js";
import { getAllQuestionnaires } from "@/api/comm.js";
export default {
  inject: ["reload"],
  data() {
    return {
      loadingText: "页面加载中",
      loading: true,
      dialogFormVisible: false,
      formLabelWidth: "10px",
      data: [
        {
          id: 23,
          name: "qiyue-lin",
          sort: 0,
          path: null,
          parent_id: 0,
          children: [
            {
              id: 25,
              name: "qiyue-lin2",
              sort: 0,
              path: "23",
              parent_id: 23,
            },
            {
              id: 26,
              name: "miss",
              sort: 0,
              path: "23",
              parent_id: 23,
              children: [
                {
                  id: 4,
                  name: "you",
                  sort: 0,
                  path: "23,26",
                  parent_id: 26,
                  children: [
                    {
                      id: 27,
                      name: "love",
                      sort: 0,
                      path: "23,26,4",
                      parent_id: 4,
                      children: [
                        {
                          id: 28,
                          name: "forever",
                          sort: 0,
                          path: "23,26,4,27",
                          parent_id: 27,
                          children: [
                            {
                              id: 29,
                              name: "never",
                              sort: 0,
                              path: "23,26,4,27,28",
                              parent_id: 28,
                              children: [],
                            },
                            {
                              id: 30,
                              name: "forget",
                              sort: 0,
                              path: "23,26,4,27,28",
                              parent_id: 28,
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 21,
          name: " 字节跳动 开发部222",
          sort: 20,
          path: null,
          parent_id: 0,
          children: [],
        },
        {
          id: 1,
          name: "技术部门",
          sort: 19,
          path: null,
          parent_id: 0,
          children: [],
        },
      ],
      defaultProps: {
        children: "children",
        label: "name",
      },
      form: {
        password: "",
        idcard: "",
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        // ----------单--------------
        account: "",
        real_name: "",
        sex: "",
        age: "",
        ages: "",
        culture: "",
        department_id: "",
        // 都有
        measure_group: "", //组合量表包
        // measurement_num: "",
        measure: [],
        show_finish_measure: "",
        begin_time: "",
        end_time: "",
        date: [],
        // 自定义填写内容
        archives: {},
        head_id: "",
        scaleName: "",
      },
      departmentList: [],
      imageUrl: "",
      fileData: {
        type: 0,
        attribution_id: 1, // 当前 用户的id
      },
      // 量表tab栏
      scale: "first",
      // 所有量表-是否使用
      checkList: [],
      // 所有量表-是否有查看权限
      checkList2: [],
      // 所有量表数据渲染
      scaleData: [],
      // 所有量表数据存储
      scaleData1: [],
      // 量表组合包-是否使用
      checkList3: [],
      //量表组合包数据
      scaleData2: [],
      // 所有量表-全选控制
      checkAll1: 0,
      checkAll2: 1,
      // 调查问卷
      checkList11: [],
      checkList22: [],
      scaleData11: [],
      questionnaireData: [],
      // 自定义档案渲染
      archivesData: [],
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6; //只能选择今天及今天之前
        },
      },
    };
  },
  created() {
    //所有量表
    this.loadScaleData();
    // 问卷
    this.loadQuestionnaires();
    // 量表组
    this.loadScaleData2();
    // 获取档案信息 / 并用户加载数据
    this.loadArchives();
    // 获取部门
    this.departmentList = JSON.parse(localStorage.getItem("departmentList"));
  },
  methods() {},
  methods: {
    // 刷新
    refresh() {
      this.reload();
    },
    // 返回
    back() {
      this.$router.go(-1); //返回上一层
    },
    // 树形结构单选
    handleNodeClick(data, checked, node) {
      if (checked === true) {
        this.checkedId = data.id;
        this.$refs.treeForm.setCheckedKeys([data.id]);
      } else {
        if (this.checkedId == data.id) {
          this.$refs.treeForm.setCheckedKeys([data.id]);
        }
      }
    },
    // 文件上传成功的狗子
    fileUploadApi(response, file, fileList) {
      this.form.head_id = response.data.id;
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    handleAvatarSuccess(response, res, file) {
      this.form.head_id = response.data.id;
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    // 图片上次校验
    beforeAvatarUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "jpg" ||
        testmsg === "JPG" ||
        testmsg === "png" ||
        testmsg === "PNG" ||
        testmsg === "bpm" ||
        testmsg === "BPM";
      const isLt50M = file.size / 1024 / 1024 < 2;
      if (!extension) {
        this.$message({
          message: "上传图片只能是jpg / png / bpm格式!",
          type: "error",
        });
        return false;
      }
      if (!isLt50M) {
        this.$message({
          message: "上传文件大小不能超过 2MB!",
          type: "error",
        });
        return false;
      }
      return extension || isLt50M;
    },

    //判断是否选择了量表，是否有权利选择结果,处理数据到  form.measure 中
    handleCheckAllChange(val) {
      var a = parseInt(val.target.value);
      if (val.target.name === "a") {
        var obj = { measure_id: a, show_result: 0 };
        var tmp = this.form.measure.some(function (item) {
          return item.measure_id === a;
        });
        if (tmp) {
          this.form.measure = this.form.measure.filter((v) => {
            return v.measure_id != val.target.value;
          });
        } else {
          this.form.measure.push(obj);
        }
      } else if (val.target.name === "b") {
        var p = this.checkList.indexOf(a);
        var index = this.form.measure.findIndex((item) => {
          return item.measure_id === a;
        });
        if (this.form.measure[index] && index != -1) {
          if (this.form.measure[index].show_result === 0) {
            this.form.measure[index].show_result = 1;
          } else {
            this.form.measure[index].show_result = 0;
          }
        }

        if (p === -1) {
          this.$message.error("请先选择该表单后，在选择此选项");
          this.checkList2 = this.checkList2.filter((v) => {
            return v != val.target.value;
          });
          return;
        }
      } else {
        let st = true;
        this.form.measure.forEach((v) => {
          if (v.measure_id == val.target.name) {
            v.max_num = val.target.value || 0;
            st = false;
          }
        });
        if (st) {
          this.$message({
            message: "警告,量表未勾选",
            type: "warning",
          });
          val.target.value = "";
        }
      }
    },
    // 全选、取消
    checkAll(res) {
      // 量表
      if (res == 1) {
        if (this.checkAll1 == 0) {
          this.scaleData.forEach((v) => {
            v.status_measure.forEach((v) => {
              this.checkList.push(v.id);
              var obj = { measure_id: v.id, show_result: 0 };
              this.form.measure.push(obj);
            });
          });
          this.checkAll1 = 1;
        } else {
          this.checkList = [];
          this.checkList2 = [];
          this.form.measure = [];
          this.checkAll1 = 0;
        }
      } else {
        if (this.checkAll2 == 1) {
          //1-先判断是否有选择的量表
          if (this.checkList.length <= 0) {
            this.$message.error("请选择量表后，再全选");
            return;
          }
          //2-把  checkList 的值 给 checkList2
          let a = this.checkList;
          this.checkList2 = a;
          //3-通过  checkList2 的值 依次 寻找measure 中的 show_result 改成 1
          this.checkList2.forEach((v, k) => {
            for (var i = 0; i < this.form.measure.length; i++) {
              if (this.form.measure[i].measure_id === v) {
                this.form.measure[i].show_result = 1;
              }
            }
          });
          this.checkAll2 = 0;
        } else {
          //1-先判断是否有选择的量表
          if (this.checkList.length <= 0) {
            this.$message.error("请选择量表后，再全选");
            return;
          }
          //3-通过  checkList2 的值 依次 寻找measure 中的 show_result 改成 1
          this.checkList2.forEach((v, k) => {
            for (var i = 0; i < this.form.measure.length; i++) {
              if (this.form.measure[i].measure_id === v) {
                this.form.measure[i].show_result = 0;
              }
            }
          });
          this.checkList2 = [];
          this.checkAll2 = 1;
        }
      }
    },
    // 组合量表
    handleCheckAllChange2(val) {
      // 清除所有;
      this.checkList3 = [];
      this.checkList3.push(parseInt(val.target.value));
    },
    // 量表数据请求
    async loadScaleData() {
      var res = await axios.get("/all_measure_classify");
      if (res.data.code === 400200) {
        this.scaleData = res.data.data;
        this.scaleData1 = res.data.data;
      } else {
        return;
      }
    },
    //组合量表包请求
    async loadScaleData2() {
      var res = await axios.get("/list_measure_group");
      this.scaleData2 = res.data.data;
    },
    // 量表查询
    queryScale() {
      var str = this.form.scaleName;
      if (str === "") {
        this.scaleData = this.scaleData1;
        return;
      }
      var obj = [{ classify_name: "查询结果", id: 1, status_measure: [] }];
      for (let k in this.scaleData1) {
        if (this.scaleData1[k].id) {
          this.scaleData1[k].status_measure.forEach((v) => {
            if (v.measure_title.indexOf(str) != -1) {
              obj[0].status_measure.push(v);
            }
          });
        }
      }
      this.scaleData = obj;
    },
    // 调查问卷 勾选、数据处理
    handleCheckAllChange3(val) {
      //console.log(1);
      var a = parseInt(val.target.value);
      if (val.target.name == "a") {
        var obj = { questionnaire_id: a, show_result: "" || 0 };
        var tmp = this.form.questionnaire.some(function (item) {
          return item.questionnaire_id == a;
        });
        if (tmp) {
          this.form.questionnaire = this.form.questionnaire.filter((v) => {
            return v.questionnaire_id != val.target.value;
          });
        } else {
          this.form.questionnaire.push(obj);
        }
      }
      if (val.target.name == "b") {
        var p = this.checkList11.indexOf(a);
        var index = this.form.questionnaire.findIndex((item) => {
          return item.questionnaire_id == a;
        });
        if (this.form.questionnaire[index] && index != -1) {
          if (this.form.questionnaire[index].show_result === 0) {
            this.form.questionnaire[index].show_result = 1;
          } else {
            this.form.questionnaire[index].show_result = 0;
          }
        }

        if (p === -1) {
          this.$message.error("请先选择该表单后，在选择此选项");
          this.checkList22 = this.checkList22.filter((v) => {
            return v != val.target.value;
          });
          return;
        }
      }
    },
    // // 页面加载数据
    loadUserData() {
      // 获取用户id
      let uid = this.$route.query.id;
      userInfo({ id: uid }).then(async (res) => {
        let {
          account,
          idcard,
          real_name,
          sex,
          age,
          ages,
          culture,
          show_finish_measure,
          head_id,
          begin_time,
          end_time,
          create_time,
          department_name,
          img,
          department_id,
          measure_group,
        } = res.data.user.data;
        this.form.account = account;
        this.form.real_name = real_name;
        this.form.sex = sex;
        this.form.age = age;
        this.form.idcard = idcard;
        this.form.ages = ages;
        this.form.culture = culture;
        this.form.show_finish_measure = show_finish_measure;
        this.form.head_id = head_id;
        this.form.begin_timebegin_time;
        this.form.end_time = end_time;
        this.form.create_time = create_time;
        this.form.department_name = department_name;
        this.form.img = img;
        this.form.department_id = department_id;
        this.form.measure_group = measure_group;
        this.form.measure = res.data.measure;
        this.form.questionnaire = res.data.questionnaire;
        //  处理时间
        this.form.date.push(begin_time * 1000);
        this.form.date.push(end_time * 1000);
        // 处理量表tab栏当前
        if (measure_group) {
          this.scale = "second";
        }
        //  处理量表
        if (res.data.measure.length > 0) {
          // 勾选
          res.data.measure.forEach((v) => {
            this.checkList.push(v.measure_id);
            if (v.show_result === 1) {
              this.checkList2.push(v.measure_id);
            }
          });
          // 次数
          this.scaleData.forEach((v) => {
            if (v.status_measure.length > 0) {
              v.status_measure.forEach((v2) => {
                res.data.measure.forEach((v3) => {
                  if (v2.id == v3.measure_id) {
                    this.$set(v2, "max_num", v3.max_num || 0);
                  }
                });
              });
            }
          });
        }
        // 处理问卷
        if (res.data.questionnaire.length > 0) {
          res.data.questionnaire.forEach((v) => {
            this.checkList11.push(v.questionnaire_id);
            if (v.show_result === 1) {
              this.checkList22.push(v.questionnaire_id);
            }
          });
        }

        // 量表组合包
        this.checkList3.push(measure_group);
        //  头像
        this.imageUrl = img;
        // 自定义档案处理
        res.data.archives.forEach((v, k) => {
          let data = {};
          if (v.value != "") {
            if (v.type === 3 || v.type === 5) {
              this.$set(this.form.archives, v.key, v.value || []);
            } else {
              this.$set(this.form.archives, v.key, v.value);
            }
          }
        });
        this.loading = false;
      });
    },
    // 修改按钮
    editBtn() {
      let begin_time = 0;
      let end_time = 0;
      if (this.form.date) {
        begin_time = parseInt(this.form.date[0] / 1000);
        end_time = parseInt(this.form.date[1] / 1000);
      }
      this.form.age = this.form.ages;
      let {
        account,
        sex,
        age,
        ages,
        culture,
        department_id,
        real_name,
        archives,
        head_id,
        password,
        idcard,
        questionnaire,
      } = this.form;
      let obj = {
        password,
        idcard,
        account,
        sex,
        age,
        ages,
        culture,
        department_id,
        begin_time,
        end_time,
        real_name,
        archives,
        head_id: head_id || 0,
        questionnaire,
      };
      // if (!obj.idcard) {
      //   this.$message({
      //     message: "身份证号码不能为空!",
      //     type: "error",
      //   });
      //   return;
      // } else {
      //   let reg = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;
      //   if (!reg.test(obj.idcard)) {
      //     this.$message({
      //       message: "请输入正确的身份证号码!",
      //       type: "error",
      //     });
      //     return;
      //   }
      // }

      this.loadingText = "正在更新用户信息";
      this.loading = true;
      if (this.scale === "first") {
        // 所有量表
        var { show_finish_measure, measure } = this.form;
        obj.show_finish_measure = show_finish_measure;
        obj.measure = measure;
      } else {
        // 量表组
        obj.measure_group = this.checkList3[0];
      }
      //  发送请求
      adminUpdateUser(obj).then((res) => {
        // //console.log(res);
        if (res.code === 400200) {
          this.$message({
            message: "恭喜你，修改成功",
            type: "success",
          });
          this.back();
        }
        this.loading = false;
      });
    },
    // 加载自定义档案信息
    loadArchives() {
      loadArchivesInfo().then((res) => {
        //console.log(res);
        this.archivesData = res.data;
        this.archivesData.forEach((v) => {
          let data = {};
          if (v.type === 3 || v.type === 5) {
            this.$set(this.form.archives, v.key, []);
          } else {
            this.$set(this.form.archives, v.key, "");
          }
        });
        // 获取用户id,加载数据
        this.loadUserData();
      });
    },
    // 问卷数据
    loadQuestionnaires() {
      getAllQuestionnaires().then((res) => {
        this.questionnaireData = res.data;
      });
    },
  },
};
</script>

<style lang="less">
.tc-evaluate-editUser {
  width: 100%;
  min-width: 1350px;
  position: relative;
  margin-top: 10px;
  // 页面功能
  .tool {
    position: absolute;
    top: -60px;
    right: 0;
    ul {
      li {
        cursor: pointer;
        list-style: none;
        float: left;
        margin-left: 10px;
        img {
          width: 100px;
        }
      }
    }
  }
  .userinfo {
    .el-input__inner {
      width: 150px;
      padding-right: 20px;
    }
  }
  .scale {
    .table {
      width: 950px;
      height: 300px;
      overflow: auto;
      border: 1px #cccccc solid;
      margin-bottom: 20px;
      .el-input__inner {
        width: 120px;
        padding-right: 20px;
      }

      table {
        width: 945px;
        text-align: center;
        overflow: auto;
        border-collapse: collapse;
        th {
          border-bottom: 1px solid #cccccc;
          border-right: 1px solid #cccccc;
        }
        tr,
        th {
          height: 20px;
          padding: 0;
          font-weight: 300;
          font-size: 14px;
        }
        tr {
          td {
            padding: 2px 5px;
            &:nth-child(1) {
              text-align: left;
            }
            .el-input-number {
              .el-input__inner {
                padding: 0;
              }
            }
          }
          &:hover {
            background: #b4e7e9;
          }
        }
        th {
          height: 30px;
          font-size: 15px;
          background: rgb(224, 245, 250);
        }
        .title {
          background: rgb(224, 245, 250);
          text-align: left;
          font-weight: 700;
          font-size: 15px;
        }
        .el-checkbox__label {
          display: none;
        }
      }
    }
    .query {
      p {
        line-height: 30px;
        font-size: 14px;
        color: #606266;
        margin-bottom: 10px;
        padding-left: 10px;
      }
    }
    .el-input__inner {
      width: 220px;
      padding-right: 0px;
    }
  }
  .avatarTitle {
    line-height: 70px;
    font-size: 14px;
    color: #606266;
    padding-left: 10px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
  }
  .avatar {
    width: 70px;
    height: 70px;
    display: block;
  }
  .avatar-uploader {
    display: flex;
    justify-content: center;
  }
  .msqtable {
    p {
      line-height: 30px;
      font-size: 14px;
      color: #606266;
      margin-bottom: 5px;
      padding-left: 10px;
    }
    .table {
      width: 850px;
      height: 300px;
      overflow: auto;
      border: 1px #cccccc solid;
      margin-bottom: 20px;
      .el-input__inner {
        width: 120px;
        padding-right: 20px;
      }

      table {
        width: 848px;
        text-align: center;
        overflow: auto;
        tr,
        th {
          height: 20px;
          padding: 0;
          font-weight: 300;
          font-size: 14px;
        }
        tr {
          td {
            &:nth-child(1) {
              text-align: left;
            }
          }
        }
        th {
          height: 30px;
          font-size: 15px;
          background: rgb(224, 245, 250);
        }
        .title {
          background: rgb(224, 245, 250);
          text-align: left;
          font-weight: 700;
          font-size: 15px;
        }
        .el-checkbox__label {
          display: none;
        }
      }
    }
  }
  .el-loading-mask {
    background-color: rgba(255, 255, 255, 0.5);
  }
  .btn {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }
}
</style>